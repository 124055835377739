import * as React from "react";
import styled from "styled-components";

export const HEIGHT = '35px';

const Image = styled.img`
height: 100%;
width: 100%;
`

const Cover = styled.div`
@keyframes AnimateCover {
    0% {
        width: 50%;
    }
    100% {
        width: 0%;
    }
}

&:before,
&:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fff;
    animation-name: AnimateCover;
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}
&:before {
    right: 50%;
}
&:after {
    left: 50%;
}
`

const Root = styled.div`
height: ${HEIGHT}; 
width: 35px;
position: relative;
filter: invert(var(--invert));
`

const Logo: React.FC = () => {
    return <Root>
        <Image src="/images/wm.png" alt="logo" />
        <Cover />
    </Root>
}

export default Logo;
