import * as React from "react";
import styled from "styled-components";

import { linkWithRef, useTitle } from "../utils";
import Logo from "../components/Logo";

const Root = styled.div`
`;

const Description = styled.p`
font-size: 1.1rem;
line-height: 2.2rem;
text-align: left;
color: #727272;
`;

// const TimeVisitorData = styled.small`
// display: block;
// margin-top: 2rem;
// color: #a4a4a4;
// `;

type ViewProps = {};

const Home: React.FC<ViewProps> = () => {
    useTitle("");
    return <Root>
        <div className="d-flex gap-3 align-items-center">
            <Logo />
            <div>
                <h5 className="m-0">Wale Moren</h5>
                <h6 className="text-muted m-0">Product Engineer</h6>
            </div>
        </div>
        <Description className="mt-5">Hey there, I’m Wale, a product engineer with a knack for creating functional, user-friendly &amp; visually appealing applications.</Description>
        <Description className="mt-3">Currently at <a target="_blank" rel="noreferrer" href={linkWithRef("https://goodboost.ai")}>Good Boost</a>. Previously at <a target="_blank" rel="noreferrer" href={linkWithRef("https://onechronos.com")}>OneChronos</a>.</Description>
        {/* <TimeVisitorData>
            08:44:26 &middot; 🇳🇬 Lagos, Nigeria &middot; Last visit from 🇮🇳 Mumbai, India
        </TimeVisitorData> */}
    </Root>
}

export default Home;
