import * as React from "react";
import { FiMenu } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import IconButton from "./IconButton";
import Logo, { HEIGHT as LOGO_HEIGHT } from "./Logo";
import Lottie from 'react-lottie';
import * as hamburgerAnimationData from "../lottie-hamburger.json";

const Root = styled.header`
padding-top: 40px;
`;

const ICB = styled(IconButton)`
filter: invert(var(--invert));
`;

type HeaderProps = {
    logoVisible?: boolean;
    commandMenuButtonPress?: () => void;
};

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hamburgerAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Header: React.FC<HeaderProps> = ({ logoVisible = false, commandMenuButtonPress }) => {
    const hamburgerLottieEl = React.useRef<Lottie>(null);

    return <Root className="d-flex align-items-center justify-content-between position-relative above">
        <div style={{ minHeight: LOGO_HEIGHT }}>{logoVisible && <NavLink className="muted" to="/home"><Logo /></NavLink>}</div>
        <ICB className="muted" href="#" onClick={commandMenuButtonPress}>
            <Lottie ref={hamburgerLottieEl} options={defaultOptions}
                height={35}
                width={35}
                speed={0.5}
                eventListeners={[
                    {
                        eventName: 'complete',
                        
                        callback: () => {
                            // setTimeout(() => {
                            // //@ts-ignore
                            // hamburgerLottieEl.current?.stop();
                            // //@ts-ignore
                            // hamburgerLottieEl.current?.play();
                            // }, 0)
                        }
                    },
                ]} />
        </ICB>
    </Root>
}

export default Header;
