import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from "./views/Home";
import Layout from "./views/Layout";
import About from "./views/About";
import Projects from "./views/Projects";
import Tools from "./views/Tools";
import Work from "./views/Work";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="tools" element={<Tools />} />
          <Route path="work" element={<Work />} />
          <Route path="/" element={<Navigate to="about" replace />} />
          <Route path="*" element={<Navigate to="about" replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
