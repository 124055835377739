import * as React from "react";
import styled from "styled-components";
import List, { ListItem } from "../components/List";
import { useTitle } from "../utils";

const Root = styled.div`
`;

const JobIcon = styled.div<{accent: string, src: string}>`
width: 50px;
height: 50px;
display: block;
margin-inline-end: 15px;
position: relative;
background: ${props => props.accent || "#FFF"};
border-radius: 5px;

&:after {
    content: "";
    background-image: url(${props => props.src});
    height: 35px;
    width: 35px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
`;

const items:ListItem[] = [
    {
        title: "Good Boost",
        description: "Full-Stack Engineer",
        prefix: <JobIcon src="https://uploads-ssl.webflow.com/6398c921e7d26e17ef4afb02/6398c96c5d7f8476d8285c3c_Logo.png" accent="rgb(245 128 35 / 14%)" />,
        href: "https://goodboost.ai",
    },
    {
        title: "Shepherd (YC W24)",
        description: "Full-Stack Engineer",
        prefix: <JobIcon src="/images/shepherd.png" accent="rgb(32 125 247 / 14%)" />,
        href: "https://shepherd.study",
    },
    {
        title: "OneChronos (YC S16)",
        description: "Front-End Engineer",
        prefix: <JobIcon src="/images/ocx.png" accent="rgb(64 129 188 / 14%)" />,
        href: "https://onechronos.com",
    },
        {
        title: "Medallion",
        description: "Software Engineer",
        prefix: <JobIcon src="/images/medallion.jpeg" accent="#345ef8" />,
        href: "https://medallion.co",
    },
    {
        title: "Minimum (YC S20)",
        description: "Front-End Engineer",
        prefix: <JobIcon src="/images/minimum.png" accent="rgb(19 89 72 / 14%)" />,
        href: "https://minimum.com",
    },
    {
        title: "Flightfox (YC S12)",
        description: "Software Engineer",
        prefix: <JobIcon src="/images/flightfox.png" accent="rgb(221 64 36 / 14%)" />,
        href: "https://flightfox.com",
    },
]

const Work: React.FC = () => {
    useTitle("Work");
    return <Root>
        <div>
            <h3>Work</h3>
            <p className="text-muted">A history of places I’ve worked at</p>
        </div>
        <hr className="my-5" />
        <div className="mt-5">
            <List items={items} />
        </div>
    </Root>
}

export default Work;
