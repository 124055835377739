import * as React from "react";
import styled from "styled-components";

import ReactMapboxGl from 'react-mapbox-gl';
import { useState } from "react";

const PADDING = "16px";

const Root = styled.span`
display: block;
width: 100%;
background: rgb(247, 247, 248);
border-radius: 22px;
position: relative;
height: 300px;

* {
    z-index: 3;
}

&:before {
    content: "";
    background: linear-gradient(rgb(231, 229, 228), rgb(250, 250, 249));
    display: block;
    position: absolute;
    inset: -1px;
    z-index: -1;
    border-radius: 22px;
}

&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: white;
    border-radius: 22px;
}

&:hover {
    &:after {
        box-shadow: var(--card-hover-shadow);
    }
}

.item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

const Map = styled.span`
display: block;
border-radius: 6px;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
position: relative;
overflow: hidden;
isolation: isolate;

&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 25px;
    bottom: -25px;
    box-shadow: 0 2px 45px 20px rgb(247 247 248);
    z-index: 5;
}
`;

const Meta = styled('span')<{visible: boolean}>`
display: block;
margin-top: 0.7rem;
transition: opacity 0.5s;
opacity: ${({ visible }) => (visible ? '1' : '0')};
position: absolute;
top: 160px;
left: ${PADDING};
right: ${PADDING};
`;

const CountryName = styled.span`
display: block;
font-weight: 700;
`;

const Description = styled.span`
font-size: 0.8rem;
line-height: 1rem;
display: block;
margin-top: 0.1rem;
word-wrap: break-word;
`;

type Country = {
    name: string,
    description: string,
    longitude: number,
    latitude: number,
};

type CountryListProps = Omit<React.ComponentProps<'span'>, "ref"> & {
    countries: Array<Country>
};

const MapBox = ReactMapboxGl({
    accessToken:
        'pk.eyJ1Ijoid2FsZW1vcmVuIiwiYSI6ImNsOTRqY3lpbDE1NnUzdmxjMGc3MDBqczkifQ.lTdn8uG0YAFvYDhN0fu0mQ'
});

const CountryList: React.FC<CountryListProps> = ({ countries, ...rest }) => {
    const [currentCountryName, setCurrentCountryName] = useState("");

    React.useEffect(() => {
        resetCurrentCountryName();
    }, []);

    React.useEffect(() => {
        const id = setInterval(() => {
            next();
        }, 4000);

        return () => clearInterval(id);
    });

    const resetCurrentCountryName = () => {
        setCurrentCountryName(countries[0].name);
    }

    const next = () => {
        const currentCountryIndex = countries.findIndex((c) => c.name === currentCountryName);

        if (currentCountryIndex + 1 < countries.length) {
            setCurrentCountryName(countries[currentCountryIndex + 1].name)
        } else {
            resetCurrentCountryName();
        }
    }

    const country = countries.find(c => c.name === currentCountryName);

    return <Root className="muted" {...rest}>
        {country && <Map>
            <MapBox
                style={"mapbox://styles/mapbox/streets-v9"}
                containerStyle={{
                    height: '160px',
                    width: '100%',
                    borderRadius: "22px 22px 0 0"
                }}
                animationOptions={{duration: 1000}}
                center={[country.longitude, country.latitude]}
            />
        </Map>}
        {
            countries.map(c => <Meta key={c.name} visible={currentCountryName === c.name}>
                <CountryName className="text-dark">{c?.name}</CountryName>
                <Description className="text-muted">{c?.description}</Description>
            </Meta>
            )
        }
    </Root >
}

export default CountryList;
