import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import { HiFastForward, HiRewind } from "react-icons/hi";

const AlbumCover = styled.img`
height: 55px;
width: 55px;
border-radius: 6px;
`

const Title = styled.small`
display: block;
font-size: 17px;
font-weight: 600;
transition: all .4s ease-in-out;
`

const Artist = styled.small`
display: block;
font-size: 14px;
color: #868686;
font-weight: 500;
transition: all .4s ease-in-out;
`

const AudioMeta = styled.span`
margin-left: 1rem;
`

const SeekParent = styled.span`
display: block;
height: 40px;
margin-top: .8em;
position: relative;
cursor: pointer;
`

const SeekTrack = styled.span`
display: block;
height: 100%;
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
`

const SeekBar = styled.span`
display: block;
height: 100%;
width: 2.5px;
background: #e3e3e3;
border-radius: 60px;
transition: all .1s cubic-bezier(0.4, 0, 1, 1);
cursor: pointer;
`

const TrackProgress = styled.span`
display: flex;
justify-content: space-between;
font-size: 12px;
font-weight: 600;
margin-top: 3px;
transition: all .4s ease-in-out;

span:last-child {
    opacity: .5;
}
`

const ProviderIcon = styled.span`
position: relative;
img {
    height: 40px;
    width: 40px;
}
display: inline-block;
margin-top: 20px;

&:before {
    content: "";
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    height: 40px;
    width: 40px;
    position: absolute;
    background: linear-gradient(180deg,#fb5a72,#fa1f3a);
    z-index: -1;
    transform-origin: 10% 90%;
    border-radius: 5px;
    transition: all .4s ease-in-out;
}
`

const TrackControls = styled.span`
display: flex;
position: absolute;
bottom: 28px;
left: 50%;
gap: 6px;
transform: translateX(-50%);
button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    svg {
        transition: all .4s ease-in-out;
    }

    &:first-child svg,
    &:nth-child(3) svg {
        color: #82869a;
    }

    &:nth-child(2) svg {
        color: #000;
    }
}
`

const Root = styled.span`
display: block;
position: relative;
padding: 16px;
width: 100%;
transition: border 1s ease-out;
isolation: isolate;

* {
    z-index: 3;
}

&:before {
    content: "";
    background: linear-gradient(rgb(231, 229, 228), rgb(250, 250, 249));
    display: block;
    position: absolute;
    inset: -1px;
    z-index: -1;
    border-radius: 22px;
}

&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: white;
    border-radius: 22px;
}

&:hover {
    &:after {
        box-shadow: var(--card-hover-shadow);
    }
}

&.playing {
    &:before,
    &:after {
        z-index: 4;
    }
    &:before {
        background: linear-gradient(270deg, #00ffbd, #2fff00);
        animation: gradient 1s ease infinite;
        background-size: 400% 400%;
        inset: -2px;
        border-radius: 24px;
    }
    &:after {
        background-color: #fa2740;
    }
    ${ProviderIcon}:before {
        transform: translateX(-50%) translateY(-50%) scale(999);
    }

    ${Title} {
        color: #fff;
    }

    ${Artist} {
        color: rgba(255, 255, 255, 0.8);
    }

    ${TrackProgress} {
        color: rgba(255, 255, 255, 1);
    }

    ${TrackControls} {
        button {
            &:first-child svg,
            &:nth-child(3) svg {
                color: rgba(255, 255, 255, 0.4);
            }
        
            &:nth-child(2) svg {
                color: #FFF;
            }
        }
    }
}
`

type AudioPlayerProps = Omit<React.ComponentProps<'span'>, "ref">;

const generateSeekerBarHeight = () => Math.floor(Math.random() * 71) + 30;
const secondsToDuration = (seconds: number) => seconds ? new Date(seconds * 1000).toISOString().slice(14, 19) : "00:00";

const seekBarArray = [...new Array(50)];

const seekBarHeights: number[] = [];

seekBarArray.map((_, i) => seekBarHeights.push(generateSeekerBarHeight()));

const tracks = [
    {
        id: "masego-tadow",
        url: "/music/Masego - tadow.mp3",
        albumArtSrc: "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/35/48/b3/3548b3c7-92bd-ea54-1e09-47a1f6f25b01/00842812109249.rgb.jpg/1000x1000bb.webp",
        albumName: "Lady Lady",
        artistName: "Masego and FKJ",
        name: "Tadow"
    },
    {
        id: "lorde-supercut",
        url: "/music/Lorde-supercut.mp3",
        albumArtSrc: "https://is2-ssl.mzstatic.com/image/thumb/Music115/v4/8d/0d/15/8d0d1532-493b-52ec-6a29-a239ced6931b/17UMGIM81023.rgb.jpg/1000x1000bb.webp",
        albumName: "Melodrama",
        artistName: "Lorde",
        name: "Supercut"
    },
    {
        id: "tems-ice2",
        url: "/music/Tems-IceT.mp3",
        albumArtSrc: "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/c6/4d/ed/c64ded15-9cd9-4bd7-a3ea-051178b2427d/195497234301.jpg/1000x1000bb.webp",
        albumName: "For Broken Ears",
        artistName: "Tems",
        name: "Ice T"
    },
    {
        id: "cleosol-whydontyou",
        url: "/music/cleosol-whydontyou.mp3",
        albumArtSrc: "https://is4-ssl.mzstatic.com/image/thumb/Music125/v4/a4/d3/aa/a4d3aadc-014d-7cdc-5e8b-ec0dcf2bf12b/859734840785_cover.jpg/1000x1000bb.webp",
        albumName: "Rose in the Dark",
        artistName: "Cleo Sol",
        name: "Why Don't You"
    },
    {
        id: "toto-africa",
        url: "/music/Toto-Africa.mp3",
        albumArtSrc: "https://is3-ssl.mzstatic.com/image/thumb/Music115/v4/69/ce/d2/69ced240-07a7-2a04-bbab-2afbacf30809/074643772822.jpg/1000x1000bb.webp",
        albumName: "Toto IV",
        artistName: "Toto",
        name: "Africa"
    },
    {
        id: "tracychapman-fastcar",
        url: "/music/tracychapman-fastcar.mp3",
        albumArtSrc: "https://is3-ssl.mzstatic.com/image/thumb/Music125/v4/2a/c1/cf/2ac1cf24-2a07-c62e-921c-357aba15d7f4/mzi.vosghqus.jpg/1000x1000bb.webp",
        albumName: "Tracy Chapman",
        artistName: "Tracy Chapman",
        name: "Fast Car"
    },
    {
        id: "paramore-pool",
        url: "/music/Paramore-pool.mp3",
        albumArtSrc: "https://is2-ssl.mzstatic.com/image/thumb/Music125/v4/e6/6f/17/e66f179c-599d-c7fd-fafe-bc792e2b5993/075679897121.jpg/1000x1000bb.webp",
        albumName: "After Laughter",
        artistName: "Paramore",
        name: "Pool"
    },
    {
        id: "tonytonitone-anniversary",
        url: "/music/TonyToniTone-Anniversary.mp3",
        albumArtSrc: "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/1a/77/92/1a779258-e968-d5eb-c4d6-3b6903603550/00731451493324.rgb.jpg/1000x1000bb.webp",
        albumName: "Sons of Soul",
        artistName: "Tony! Toni! Toné!",
        name: "Anniversary"
    }
]


const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [seekBarHoverIndex, setSeekBarHoverIndex] = useState(-1);
    const [currentTrackId, setCurrentTrackId] = useState<string | null>(null);

    const [audioDuration, setAudioDuration] = useState(0);
    const [audioCurrentTime, setAudioCurrentTime] = useState(0);

    const audioRef = useRef<HTMLAudioElement | null>(null);
    const audioTrackPercentage = ((audioCurrentTime / audioDuration) * 100);
    const [textColor, setTextColor] = useState("");

    useEffect(() => {
        resetCurrentTrackId();
        setTextColor(document.body.style.getPropertyValue('--bs-body-color'));

        return () => {
            audioRef.current?.pause();
        }
    }, []);

    const resetCurrentTrackId = () => {
        setCurrentTrackId(tracks[0].id);
    }

    const currentTrack = tracks.find(t => t.id === currentTrackId);

    const updateAudioCurrentTime = (e: Event) => {
        setAudioCurrentTime((e.target as HTMLAudioElement).currentTime);
    }

    const playNext = () => {
        const currentTrackIndex = tracks.findIndex((t) => t.id === currentTrackId);

        if (currentTrackIndex + 1 < tracks.length) {
            setCurrentTrackId(tracks[currentTrackIndex + 1].id)
        } else {
            resetCurrentTrackId();
        }
    }

    const playPrevious = () => {
        const currentTrackIndex = tracks.findIndex((t) => t.id === currentTrackId);

        if (currentTrackIndex !== 0) {
            setCurrentTrackId(tracks[currentTrackIndex - 1].id)
        } else {
            setCurrentTrackId(tracks[tracks.length - 1].id)
        }
    }

    const playListener = () => {
        setIsPlaying(true);
        document.body.style.setProperty('--bs-body-color', 'white');
        document.body.style.setProperty('--invert', '1');
    }

    const pauseListener = () => {
        setIsPlaying(false);
        setTimeout(() => {
            document.body.style.setProperty('--bs-body-color', textColor);
            document.body.style.setProperty('--invert', '0');
        }, 200);
    }

    const endedListener = () => {
        audioRef.current?.setAttribute("completed", "true");
        playNext();
    }

    const audio = useMemo(() => {
        const previousTrackCompleted = audioRef.current?.getAttribute("completed") === "true";
        if (audioRef.current) {
            audioRef.current.currentTime = 0;

            audioRef.current.removeEventListener("timeupdate", updateAudioCurrentTime);
            audioRef.current.removeEventListener("play", playListener);
            audioRef.current.removeEventListener("pause", pauseListener);
            audioRef.current.removeEventListener("ended", endedListener);

            audioRef.current.src = "";
        }

        const audio = new Audio(currentTrack?.url);
        audioRef.current = audio;
        audio.currentTime = 0;
        audio.preload = "metadata";
        audio.volume = 0.01;


        if (previousTrackCompleted || isPlaying) {
            audio.play()
        }

        audio.addEventListener("timeupdate", updateAudioCurrentTime);
        audio.addEventListener("play", playListener);
        audio.addEventListener("pause", pauseListener);
        audio.addEventListener("ended", endedListener);

        audio.onloadedmetadata = () => {
            setAudioDuration(audio.duration);
        }
        return audio;
    }, [currentTrack?.url]);

    const togglePlay = () => {
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
    }

    return <Root className={isPlaying ? "playing" : ""} {...props}>
        <span className="d-flex align-items-center above position-relative">
            <AlbumCover src={currentTrack?.albumArtSrc} />
            <AudioMeta>
                <Title>{currentTrack?.name}</Title>
                <Artist>{currentTrack?.artistName} &middot; {currentTrack?.albumName}</Artist>
            </AudioMeta>
        </span>
        <SeekParent className="above position-relative" onMouseLeave={() => setSeekBarHoverIndex(-1)}>
            <SeekTrack>
                {
                    seekBarArray.map((_, i) => {
                        const isHoverBeyond = i <= seekBarHoverIndex;
                        const hasPlayedPast = audioTrackPercentage / 2 >= i && !!audioTrackPercentage;

                        return <SeekBar key={i} onMouseOver={() => setSeekBarHoverIndex(i)} style={{ height: `${seekBarHeights[i]}%`, transform: isHoverBeyond ? "scale(1.2)" : "", backgroundColor: (isHoverBeyond || (hasPlayedPast && seekBarHoverIndex == -1)) ? (isPlaying ? "white" : "red") : (isPlaying ? "rgba(255, 255, 255, 0.4)" : "#e3e3e3") }} />
                    })
                }
            </SeekTrack>
        </SeekParent>
        <TrackProgress className="above position-relative">
            <span>{secondsToDuration(audioCurrentTime)}</span>
            <span>{secondsToDuration(audioDuration)}</span>
        </TrackProgress>
        <ProviderIcon>
            <a className="muted" href="https://music.apple.com/ng/playlist/walemoren-com/pl.u-V9D7mPEUBrq8Wz2" target="_blank"><img alt="Apple Music" src="/images/apple-music-logo.png" /></a>
        </ProviderIcon>
        <TrackControls className="above">
            <button onClick={playPrevious}><HiRewind size={"30px"} /></button>
            <button onClick={togglePlay}>{isPlaying ? <BsFillPauseCircleFill size={"30px"} /> : <BsFillPlayCircleFill size={"30px"} />}</button>
            <button onClick={playNext}><HiFastForward size={"30px"} /></button>
        </TrackControls>
    </Root>
}

export default AudioPlayer;
