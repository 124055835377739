import * as React from "react";
import styled from "styled-components";
import AudioPlayer from "../components/AudioPlayer";
import CountryList from "../components/CountryList";
import Library from "../components/Library";
import { linkWithRef, useTitle } from "../utils";


const Root = styled.div`
p small {
    word-break: break-all;
}
`;

const LibraryParent = styled.span`
float: left;
padding: 16px;
padding-inline-start: 0;
width: 400px;

@media(max-width: 992px) {
    padding-inline: 0;
    width: 100%;
}
`;

const AudioPlayerParent = styled.span`
float: right;
padding: 16px;
width: 400px;

@media(max-width: 992px) {
    padding-inline: 0;
    width: 100%;
}
`;

const CountryListParent = styled.span`
float: left;
padding: 16px;
padding-inline-start: 0;
width: 400px;

@media(max-width: 992px) {
    padding-inline: 0;
    width: 100%;
}
`;

const Caption = styled.small.attrs({className: "position-relative above"})`
font-weight: bold;
font-size: 0.7rem;
display: block;
margin-top: 0.5rem;
text-align: center;
color: var(--bs-body-color);
opacity: 0.5;
`;

type ViewProps = {};
const countries = [
    {
        name: "England 🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        description: `Football`,
        latitude: 51.5068,
        longitude: -0.1309
    },
    {
        name: "Netherlands 🇳🇱",
        description: `Bicycles, Beer & Electric cars. Also, everyone has "van" in their names; Wonder what that means.`,
        latitude: 52.3676,
        longitude: 4.9041
    },
    {
        name: "U.A.E 🇦🇪",
        description: "Everything's golden for absolutely no reason.",
        latitude: 25.2667,
        longitude: 55.2929
    },
    {
        name: "Sweden 🇸🇪",
        description: "Swedes always seem to be lost and for some reason ask me for directions.",
        latitude: 59.3293,
        longitude: 18.0686
    },
    {
        name: "Poland 🇵🇱",
        description: "Why's it so cold and where did the letter Q go?",
        latitude: 52.2297,
        longitude: 21.0122
    },
    {
        name: "India 🇮🇳",
        description: "Population: 1 billion. Rickshaws: 5 billion.",
        latitude: 19.0760,
        longitude: 72.8777
    },
    {
        name: "France 🇫🇷",
        description: "Not sure what everyone's doing but I'll have some Chardonnay and Salmon to blend in.",
        latitude: 48.8566,
        longitude: 2.3522
    },
    {
        name: "Maldives 🇲🇻",
        description: "I saw a shark for the first time, pretty cool!",
        latitude: 4.1755,
        longitude: 73.5093
    },
    {
        name: "Nigeria 🇳🇬",
        description: "Yeah I'll leave this one for you to imagine.",
        latitude: 6.5245,
        longitude: 3.3763
    }
]

const reads = [
    {
        title: "Principles",
        author: "Ray Dalio",
        description: "A \"manual\" for life and work",
        currentlyReading: true,
        coverSrc: "/images/book-principles-dalio.png"
    },
    {
        title: "The Psychology of Money",
        author: "Morgan Housel",
        description: "A textbook about $ if you've been living under a rock",
        currentlyReading: false,
        coverSrc: "/images/book-psych-money.jpeg"
    },
    {
        title: "Sapiens: A Brief History of Humankind",
        author: "Yuval Noah Harari",
        description: "This made my existential crisis worse, and I'm only on page 6!",
        currentlyReading: false,
        coverSrc: "/images/book-sapiens.jpeg"
    }
]

const About: React.FC<ViewProps> = () => {
    useTitle("About")
    return <Root className="clearfix">
        <p className="mt-4">
            <span className="position-relative above">Hey there, I’m Wale, a product engineer with a knack for creating functional, user-friendly & visually appealing applications.</span>
            <br />
            <LibraryParent className="position-relative above">
                <Library reads={reads} />
                <Caption>My tiny library</Caption>
            </LibraryParent>
            <br />
            <span className="position-relative above">I'm currently working at <a target="_blank" rel="noreferrer" href={linkWithRef("https://onechronos.com")}>OneChronos</a> as a Frontend engineer.</span>
            <span className="position-relative above">Prior to OneChronos I worked at <a target="_blank" rel="noreferrer" href={linkWithRef("https://flightfox.com/")}>Flightfox</a> as a Software engineer.</span>
            <AudioPlayerParent>
                <AudioPlayer />
                <Caption>For ambience</Caption>
            </AudioPlayerParent>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <span className="position-relative above">I've also worked with some other venture capital backed startups such as <a target="_blank" rel="noreferrer" href={linkWithRef("https://minimum.eco")}>Minimum</a> &amp; <a target="_blank" rel="noreferrer" href={linkWithRef("https://medallion.co/")}>Medallion</a> as a contractor.</span>
            <br />
            <br />
            <CountryListParent>
                <CountryList countries={countries} />
                <Caption>Every country I've been to</Caption>
            </CountryListParent>
            <br />
            <br />
            <span className="position-relative above">Outside of work my hobbies include reading books, exploring new countries &amp; listening to music.<cite>[1]</cite></span>
            <br />
            <br />
            <small className="position-relative above"><i>1. <a target="_blank" rel="noreferrer" href="https://imgs.xkcd.com/comics/conversation.png">https://imgs.xkcd.com/comics/conversation.png</a></i></small>
        </p>
    </Root>
}

export default About;
