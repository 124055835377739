import * as React from "react";
import { FocusEvent, MouseEvent, useRef } from "react";
import styled from "styled-components";
import { linkWithRef } from "../utils";

const Root = styled.div`
position: relative;
`

const HoverState = styled.div`
height: 48px;
width: calc(100% + 30px);
max-width: 100vw;
border-radius: 6px;
border: 1px solid #eee;
position: absolute;
transition: all .2s ease-in-out;
display: none;
z-index: 1;
left: 50%;
transform: translateX(-50%);
`;

const ListItemLi = styled.li`

animation-direction: alternate;
animation-fill-mode: backwards;
`

const ListItemTitle = styled.span`
font-weight: 600;
`

const ListItemDescription = styled.span`
font-size: 0.9rem;
`

const ListItemMeta = styled.div`

${ListItemTitle},
${ListItemDescription} {
    display: block;
}

`

const ListItemLink = styled.a`
outline: 0;
display: flex;
align-items: center;
padding-block: 15px;
border-radius: 10px;
position: relative;
z-index: 2;
transition: all .2s ease-in-out;
&:hover {
    opacity: 1 !important;
}

`

const ListItemPrefix = styled.div`
flex-shrink: 0;
`

const ListParent = styled('ul')<{itemCount: number}>`
list-style: none;
width: 100%;
padding: 0;
margin: 0;

@keyframes FadeUp {
    0% {
        opacity: 0;
        transform: translateY(100%); 
    }
    100% {
        opacity: 1;
        transform: translateY(0); 
    }
}

&:hover {
    ${ListItemLink} {
        opacity: .6;
    }
}

${ListItemLi} {
    animation-name: FadeUp;
    animation-duration: 1s;
    ${props => () => {
        let styles = "";
        for (var i = 1; i <= props.itemCount; i++) {
            styles += `&:nth-child(${i}) { animation-delay: ${i*0.1}s }`
        }
        return styles;
    }}
}
`

export type ListItem = {
    title: React.ReactNode | string;
    description?: React.ReactNode | string;
    prefix?: React.ReactNode | string;
    href?: string;
};

type ListProps = {
    items: ListItem[]
};

const List: React.FC<ListProps> = ({ items }) => {
    const hoverStateRef = useRef<HTMLDivElement>(null);
    const updateHoverState = (e: MouseEvent<HTMLAnchorElement> | FocusEvent<HTMLAnchorElement>) => {
        if (hoverStateRef.current) {
            hoverStateRef.current.style.top = `${e.currentTarget?.offsetTop}px`;
            hoverStateRef.current.style.height = `${e.currentTarget?.offsetHeight}px`;
            hoverStateRef.current.style.display = 'block';
        }
    }
    return <Root>
        <HoverState ref={hoverStateRef} />
        <ListParent itemCount={items.length}>
            {
                items.map((item, i) => {
                    return <ListItemLi key={i}>
                        <ListItemLink target="_blank" rel="noreferrer" onFocus={updateHoverState} onMouseOver={updateHoverState} className="muted" href={item.href ? linkWithRef(item.href) : "#"}>
                            <ListItemPrefix>{item.prefix}</ListItemPrefix>
                            <ListItemMeta>
                                <ListItemTitle>{item.title}</ListItemTitle>
                                <ListItemDescription className="text-muted">{item.description}</ListItemDescription>
                            </ListItemMeta>
                        </ListItemLink>
                    </ListItemLi>
                })
            }
        </ListParent>
    </Root>
}

export default List;