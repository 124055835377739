import * as React from "react";
import styled from "styled-components";

const PADDING = "16px";

const Slides = styled.span`
display: flex;
padding: 0;
list-style: none;
margin: 0;
  
overflow-x: auto;
scroll-snap-type: x mandatory;

scroll-behavior: smooth;
-webkit-overflow-scrolling: touch;

/*
scroll-snap-points-x: repeat(300px);
scroll-snap-type: mandatory;
*/

&::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Slide = styled.span`
display: block;
scroll-snap-align: start;
flex-shrink: 0;
transform-origin: center center;
transform: scale(1);
transition: transform 0.5s;
position: relative;
width: 100%;
padding: ${PADDING};
`;

const CurrentlyReadingRead = styled.span`
display: flex;
gap: 10px;
img {
    height: 75px;
    width: 75px;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    flex-shrink: 0;
}

span.meta {
    display: block;
}

span.author {
    display: block;
    font-size: 11px;
    color: #7c828d;
    font-weight: 700;
    text-transform: uppercase;
}

span.title {
    font-size: 14px;
    font-weight: 700;
    color: #2e3b4a;
    margin-bottom: 3px;
}

span.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    font-weight: 500;
    color: #8e939b;
    margin: 0;
}
`;

const Label = styled.span`
display: block;
font-size: 12px;
color: #868686;
font-weight: 600;
margin-bottom: 10px;

&.currently-reading {
    color: #645aea;
}
`;

const Root = styled.span`
display: block;
width: 100%;
border-radius: 22px;
position: relative;

* {
    z-index: 3;
}

&:before {
    content: "";
    background: linear-gradient(rgb(231, 229, 228), rgb(250, 250, 249));
    display: block;
    position: absolute;
    inset: -1px;
    z-index: -1;
    border-radius: 22px;
}

&:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: white;
    border-radius: 22px;
}

&:hover {
    &:after {
        box-shadow: var(--card-hover-shadow);
    }
}
`;

type Read = {
    title: string;
    author: string;
    description: string;
    currentlyReading: boolean;
    coverSrc: string;
};

type LibraryProps = Omit<React.ComponentProps<'span'>, "ref"> & {
    reads: Array<Read>
};

const Library: React.FC<LibraryProps> = ({ reads, ...rest }) => {

    const slidesRef = React.useRef(null);

    React.useEffect(() => {
        setInterval(() => {
            // @ts-ignore
            const width = slidesRef.current?.clientWidth;
            // @ts-ignore
            const currentScrollPos = slidesRef.current?.scrollLeft;

            // @ts-ignore
            slidesRef.current?.scroll({
                left: currentScrollPos === width ? 0 : currentScrollPos + width,
                behavior: 'smooth',
            });
        }, 3000);
    }, []);

    return <Root className="muted" {...rest}>
        <Slides className="above position-relative" ref={slidesRef}>
            {
                reads.sort((a,b) => Number(b.currentlyReading) - Number(a.currentlyReading)).map(r => <Slide key={r.title}>
                    {r.currentlyReading ? <Label className="currently-reading">Currently Reading</Label> : <Label className="previously-read">Previously Read</Label>}
                    <CurrentlyReadingRead>
                        <img src={r.coverSrc} title={r.title} />
                        <span className="meta">
                            <span className="author">{r.author}</span>
                            <span className="title">{r.title}</span>
                            <span className="description">{r.description}</span>
                        </span>
                    </CurrentlyReadingRead>
                </Slide>)
            }

        </Slides>
    </Root>
}

export default Library;