import * as React from "react";
import { useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import CommandMenu from "../components/CommandMenu";
import Header from "../components/Header";

const Root = styled.div`
overflow: hidden;
min-height: 100vh;
`;

const Content = styled.div`
margin-block: 70px;
padding-block: 70px;
`;

const Footer = styled.footer`
    color: #aaa;
    font-size: 12px;
    text-align: center;
    padding-bottom: 16px;
`

type CommandMenuHandle = React.ElementRef<typeof CommandMenu>;

const Layout: React.FC = () => {
    const commandMenuRef = useRef<CommandMenuHandle>(null);
    const openCommandMenu = () => {
        commandMenuRef.current?.toggleOpen();
    }

    const location = useLocation();

    return <Root>
        <CommandMenu ref={commandMenuRef} />
        <div className="container">
            <div className="col-md-8 offset-md-2">
                <Header logoVisible={location.pathname !== "/home"} commandMenuButtonPress={openCommandMenu} />
                <Content>
                    <Outlet />
                </Content>
{/*                 <Footer>
                    This website is for entertainment purposes only (if I say so myself). If you disagree with something please disregard it as a bad joke.
                </Footer> */}
            </div>
        </div>
    </Root>
}

export default Layout;
