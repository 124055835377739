import * as React from "react";
import styled from "styled-components";

export const HEIGHT = 72;

const Root = styled.a`
width: 40px;
height: 40px;
border-radius: 6px;
display: inline-flex;
align-items: center;
justify-content: center;
border: 0 solid transparent;
transition: all .2s ease-out;

&:hover {
    background-color: #f4f4f4;
}
&:active {
    border: 3px solid #eee;
}
`;

type IconButtonProps = Omit<React.ComponentProps<'a'>, "ref"> & {
    children: React.ReactNode;
};

const IconButton: React.FC<IconButtonProps> = ({children, ...rest}) => {
    return <Root className="muted" {...rest}>
        {children}
    </Root>
}

export default IconButton;