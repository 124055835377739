import { Project } from "./types";

const projects: Project[] = [
    {
        title: "Piings",
        date: "November 2023",
        links: [
            {
                title: "Piings.com",
                url: "https://www.piings.com/"
            }
        ],
        description: `Measure latency between your browser & different cloud providers.`,
        featured: true
    },
    {
        title: "Android Open Source Project",
        date: "April 2015",
        links: [
            {
                title: "Google search",
                url: "https://www.google.com/search?q=ltimized"
            }
        ],
        description: `I built a bunch of CyanogenMod & Android Open Source Project (AOSP) themes under the name "Themeloft" after highschool and sold them on the Google play store, sold ~$30K in total. They got pirated so much that you can probably still download the APK file 7 years later`,
        featured: true
    },
    {
        title: "Wecover",
        date: "December 2021",
        links: [
            {
                title: "trywecover.com",
                url: "https://trywecover.com"
            },
            {
                title: "App Store",
                url: "https://apps.apple.com/ng/app/wecover/id1612166833"
            },
            {
                title: "Google Play",
                url: "https://play.google.com/store/apps/details?id=com.octade.wecover"
            }
        ],
        imageUrl: "/images/wecover.png",
        description: "An insurance startup that provides flexible & affordable car insurance cover through a mobile application.",
        featured: true
    },
    {
        title: "Appeal",
        date: "October 2016",
        links: [
            {
                title: "Themeforest",
                url: "https://themeforest.net/item/appeal-a-news-blog-shop-wordpress-theme/17355806"
            },
            {
                title: "Demo",
                url: "http://owale.co/appeal/demo"
            }
        ],
        description: "WordPress Blog Theme"
    },
    {
        title: "Typewrite.js",
        date: "September 2016",
        links: [
            {
                title: "Demo",
                url: "http://ola-wale.github.io/typewritejs"
            }
        ],
        description: "Javascript Library that 'writes' text like a typewriter"
    },
    {
        title: "June",
        date: "December 2016",
        links: [
            {
                title: "Demo",
                url: "http://owale.co/June"
            }
        ],
        description: "Personal AngularJs Template"
    },
    {
        title: "Poke(test?)",
        date: "June 2017",
        links: [
            {
                title: "Demo",
                url: "http://dev.owale.co/poketest/"
            }
        ],
        description: "Index of Pokemons cross-referenced with their stats and item's held using PokeApi.co"
    },
    {
        title: "Crypto Portfolio",
        date: "February 2018",
        links: [
            {
                title: "Github",
                url: "https://github.com/ola-wale/Mobile-Applications-Project--Portfolio-App"
            }
        ],
        description: "A simple ionic 3 crypto portfolio app I made for my Mobile Applications university class"
    },
    {
        title: "Cardies",
        date: "June 2018",
        links: [
            {
                title: "Demo",
                url: "http://dev.owale.co/cardies/"
            },
            {
                title: "Github",
                url: "https://github.com/ola-wale/Cardies"
            }
        ],
        description: "React Profile & City Card Components"
    },
    {
        title: "Arduino-Bitcoin-Ticker",
        date: "October 2017",
        links: [
            {
                title: "Github",
                url: "https://github.com/ola-wale/Arduino-Bitcoin-Ticker"
            }
        ],
        description: "Fetches bitcoin's price from various exchanges and outputs it to a 16x2 display shield"
    },
    {
        title: "FirePaste (MacOS)",
        date: "December 2017",
        links: [
            {
                title: "Github",
                url: "https://github.com/ola-wale/FirePaste-MacOS"
            }
        ],
        description: "A Self hosted Android(copy)->MacOS(paste) solution. Observes Firebase's Realtime Database, updates MacOS's clipboard with value changes"
    },
    {
        title: "FirePaste (Android)",
        date: "December 2017",
        links: [
            {
                title: "Github",
                url: "https://github.com/ola-wale/FirePaste-Android"
            }
        ],
        description: "A Self hosted Android(copy)->MacOS(paste) solution. Monitors Android's Clipboard, copies new copied text to Firebase's Realtime Database"
    }
]

export default projects;