import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Command } from "cmdk";
import styled from "styled-components";
import { FiGithub, FiLinkedin, FiPackage, FiSettings, FiAtSign, FiInfo, FiBriefcase, FiFileText, FiHome } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

const HoverState = styled.div`
height: 48px;
width: 100%;
border-radius: 6px;
background-color: #f3f3f3;
position: absolute;
transition-timing-function: ease;
display: block;
transition-duration: .2s;
z-index: 9;
box-shadow: 0 3px 3px rgb(0 0 0 / 2%);
border: 1px solid #ddd;
opacity: 0;
left: 50%;
transform: translateX(-50%);
`;

const CommandDialog = styled(Command.Dialog)`
outline: 0;
`;

const CommandItem = styled(Command.Item)`
&.selected {
    opacity: 1;
}
`;

type CommandMenuProps = {

};

type CommandMenuHandle = {
    toggleOpen: () => void
};

type CommandItem = React.ComponentProps<typeof CommandItem>

const Item = ({ children, onActive, dialogValue, value, ...rest }: { children: string | React.ReactNode, onActive: (el: HTMLDivElement) => void, dialogValue?: string } & CommandItem) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (dialogValue === value && itemRef.current) {
            setSelected(true);
            onActive(itemRef.current)
        } else {
            setSelected(false);
        }
    }, [dialogValue]);

    return <CommandItem className={clsx({selected})} value={value} {...rest} ref={itemRef}>{children}</CommandItem>
}

const CommandMenu = React.forwardRef<CommandMenuHandle, CommandMenuProps>((props, ref) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const hoverStateRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => setOpen((open) => !open);

    // Toggle the menu when ⌘K is pressed
    React.useEffect(() => {
        const down = (e: any) => {
            if (e.metaKey) {
                toggleOpen();
            }
        }

        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    const onItemActive = (e: any) => {
        if (hoverStateRef.current) {
            hoverStateRef.current.style.top = `${e.offsetTop}px`;
            hoverStateRef.current.style.width = `${e.offsetWidth}px`;
            hoverStateRef.current.style.opacity = `1`;
        }
    }

    const getValue = () => {
        let value;

        switch (location.pathname) {
            case "/home":
                value = "home";
                break;
            case "/about":
                value = "about";
                break;
            case "/work":
                value = "work";
                break;
            case "/projects":
                value = "projects";
                break;
            case "/tools":
                value = "tools";
                break;
            default:
                value = "";
                break;
        }

        return value;
    }

    useImperativeHandle(ref, () => ({
        toggleOpen,
    }));

    useEffect(() => {
        setValue(getValue())
    }, [location]);

    return (
        <CommandDialog tabIndex={1} value={value} onValueChange={(v) => setValue(v)} onMouseLeave={() => setValue(getValue())} open={open} onOpenChange={setOpen} label="Global Command Menu">
            <HoverState ref={hoverStateRef} />
            <Command.List>
                <Item onSelect={() => { navigate("/home"); toggleOpen() }} value="home" dialogValue={value} onActive={onItemActive}><FiHome /> Home</Item>
                <Item onSelect={() => { navigate("/about"); toggleOpen() }} value="about" dialogValue={value} onActive={onItemActive}><FiInfo /> About</Item>
                <Item onSelect={() => { navigate("/work"); toggleOpen() }} value="work" dialogValue={value} onActive={onItemActive}><FiBriefcase /> Work</Item>
                <Item onSelect={() => { navigate("/projects"); toggleOpen() }} value="projects" dialogValue={value} onActive={onItemActive}><FiPackage /> Projects</Item>
                <Item onSelect={() => { navigate("/tools"); toggleOpen() }} value="tools" dialogValue={value} onActive={onItemActive}><FiSettings /> Tools</Item>
                <Item onSelect={() => { window.open("https://walemoren.com/files/woren.pdf", "_blank"); toggleOpen() }} value="resume" dialogValue={value} onActive={onItemActive}><FiFileText /> Resume</Item>

                <Command.Group heading="Stay in touch">
                    <Item onSelect={() => { window.open("https://github.com/ola-wale", "_blank"); toggleOpen() }} value="github" dialogValue={value} onActive={onItemActive}><FiGithub /> Github</Item>
                    <Item onSelect={() => { window.open("https://linkedin.com/in/walemoren", "_blank"); toggleOpen() }} value="linkedin" dialogValue={value} onActive={onItemActive}><FiLinkedin /> LinkedIn</Item>
                    <Item onSelect={() => { window.open("mailto:wale@walemoren.com", "_blank"); toggleOpen() }} value="email" dialogValue={value} onActive={onItemActive}><FiAtSign /> Email</Item>
                </Command.Group>
            </Command.List>
        </CommandDialog>
    )
})

export default CommandMenu;
